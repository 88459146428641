import { useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import styles from "./index.module.scss";
import { showPopup } from "../../../redux/action-creaters/popup";
import { popupTypes } from "../../../redux/reducers/popupReducer";
import useIsMobile from "../../../hooks/useIsMobile";

export default function Video() {
  const { mainSlider } = useSelector((state) => state.content);
  const videoRef = useRef();
  const slider = mainSlider[0];
  const dispatch = useDispatch();
  const { isDetected, isMobile } = useIsMobile();

  const video = useMemo(() => {
    if (!isDetected) {
      return "";
    }
    return isMobile ? "/video2.mp4" : "site.mp4";
  }, [isMobile, isDetected]);

  return (
    <div className={styles.Video}>
      <div className={"container"}>
        <div className={styles.Content}>
          <h1>{slider.NAME}</h1>
          <div
            className={styles.Title}
            dangerouslySetInnerHTML={{ __html: slider.PREVIEW_TEXT }}
          ></div>
          <div
            className={styles.Full}
            onClick={() => {
              dispatch(
                showPopup(
                  popupTypes.videoFull,
                  "https://rutube.ru/play/embed/b511f3f5ac1b78ed7cad46d6611e7bb4?skinColor=0e8dee",
                ),
              );
            }}
          >
            Смотреть полное видео
          </div>
        </div>
      </div>
      <video
        className={clsx(isMobile && styles.VideoMobile)}
        key={isDetected}
        ref={videoRef}
        autoPlay
        muted
        loop
      >
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
}
