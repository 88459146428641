import { useState, useEffect } from "react";

export default function useIsMobile() {
  const [isDetected, setIsDetected] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      if (typeof window !== "undefined") {
        setIsMobile(/Mobi|Android|iPhone/i.test(navigator.userAgent));
        setIsDetected(true);
      }
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return {
    isMobile,
    isDetected,
  };
}
